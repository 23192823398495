import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Dots from 'theme/ui/icons/bend-icons/loaders/dots'
import languageDetector from 'utils/language-detector'

export const useRedirect = (to: string) => {
  const router = useRouter()

  // language detection
  useEffect(() => {
    if (!to) return
    const detectedLng = languageDetector.detect()
    if (to.startsWith('/' + detectedLng) && router.route === '/404') {
      // prevent endless loop
      router.replace('/' + detectedLng + router.route)
      return
    }

    languageDetector.cache?.(detectedLng as string)
    router.replace('/' + detectedLng + to)
  }, [router, to])

  return <></>
}

export const Redirect = () => {
  const router = useRouter()
  useRedirect(router.asPath)
  return <></>
}

// eslint-disable-next-line react/display-name
export const getRedirect = (to: string) => () => {
  useRedirect(to)
  return <Dots />
}
