import useTheme from 'hooks/common/useTheme'
import { Flex } from 'rebass/styled-components'

const Dots: React.FC = () => (
  <Flex sx={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, justifyContent: 'center', alignItems: 'center' }}>
    <Loader />
  </Flex>
)

export const Dots2: React.FC = () => (
  <Flex sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
    <Loader />
  </Flex>
)

export default Dots

const Loader: React.FC = () => {
  const { colors } = useTheme()
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='200px'
      height='200px'
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
    >
      <circle cx='27.5' cy='57.5' r='5' fill={colors.blue[1000]}>
        <animate
          attributeName='cy'
          calcMode='spline'
          keySplines='0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5'
          repeatCount='indefinite'
          values='57.5;42.5;57.5;57.5'
          keyTimes='0;0.3;0.6;1'
          dur='1s'
          begin='-0.6s'
        ></animate>
      </circle>{' '}
      <circle cx='42.5' cy='57.5' r='5' fill={colors.blue[700]}>
        <animate
          attributeName='cy'
          calcMode='spline'
          keySplines='0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5'
          repeatCount='indefinite'
          values='57.5;42.5;57.5;57.5'
          keyTimes='0;0.3;0.6;1'
          dur='1s'
          begin='-0.44999999999999996s'
        ></animate>
      </circle>{' '}
      <circle cx='57.5' cy='57.5' r='5' fill={colors.blue[400]}>
        <animate
          attributeName='cy'
          calcMode='spline'
          keySplines='0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5'
          repeatCount='indefinite'
          values='57.5;42.5;57.5;57.5'
          keyTimes='0;0.3;0.6;1'
          dur='1s'
          begin='-0.3s'
        ></animate>
      </circle>{' '}
      <circle cx='72.5' cy='57.5' r='5' fill={colors.blue[100]}>
        <animate
          attributeName='cy'
          calcMode='spline'
          keySplines='0 0.5 0.5 1;0.5 0 1 0.5;0.5 0.5 0.5 0.5'
          repeatCount='indefinite'
          values='57.5;42.5;57.5;57.5'
          keyTimes='0;0.3;0.6;1'
          dur='1s'
          begin='-0.15s'
        ></animate>
      </circle>
    </svg>
  )
}
