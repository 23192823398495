// @ts-check

/**
 * @type {import('next-i18next').UserConfig}
 */
 module.exports = {
  // https://www.i18next.com/overview/configuration-options#logging
  debug: false,
  i18n: {
    defaultLocale: 'en',
    locales: ['en'],
  },
  /** To avoid issues when deploying to some paas (vercel...) */
  localePath: './src/locales',

  reloadOnPrerender: process.env.NODE_ENV === 'development',

  interpolation: {
    skipOnVariables: false
  },

  /**
   * @link https://github.com/i18next/next-i18next#6-advanced-configuration
   */
  // saveMissing: false,
  // strictMode: true,
  // serializeConfig: false,
  // react: { useSuspense: false }
}